import type { IAccessibilityPropSDKProps } from '@wix/editor-elements-corvid-utils';
import { a11yBlackList, filteredA11y } from '@wix/editor-elements-common-utils';
import type React from 'react';

export const getAccessibilityAttributes = (
  attributes: IAccessibilityPropSDKProps,
) => {
  const {
    role,
    tabIndex,
    tabindex,
    screenReader,
    lang,
    ariaAttributes = {},
  } = attributes;
  const prefixedAriaAttributes: React.AriaAttributes = Object.entries(
    ariaAttributes,
  ).reduce((prev, [key, value]) => {
    return { ...prev, [`aria-${key}`.toLowerCase()]: value };
  }, {});
  return {
    role,
    tabIndex: tabIndex ?? tabindex,
    screenReader,
    ariaAttributes: filteredA11y(
      prefixedAriaAttributes,
      a11yBlackList,
    ) as React.AriaAttributes,
    lang,
  };
};
